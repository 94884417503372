import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { media } from "./mediaCSS"
import MobileHeader from "./mobileHeader"
import styled from "styled-components"
import ImgLogo from "./imgLogo"
import SubNavLinks from "./subNavLinks"

const MobilePWAHeader = styled.header`
  display: none;
  justify-content: space-between;
  background: linear-gradient(#e51937 0%, 40%, #000000 40% 100%);

  color: white;
  font-size: 30px;

  width: 100%;
  position: fixed;
  top: 0;
  height: 60px;

  ${media.mobilePWA`
    display: flex;

  `}
`

const DesktopTabletHeader = styled.header`
  min-height: 80px;
  font-weight: 900;
  font-family: "Oswald";
  display: flex;
  background: linear-gradient(#e51937 0%, 40%, #000000 40% 100%);
  border-bottom: 3px solid #e51937;

  ${media.mobile`
  display: none;
  `}
`

const HeaderSpacerLeft = styled.div`
  flex: 1 15%;
  display: none;
  ${media.desktop`
  display: block;
  `}
`

const HeaderSpacerRight = styled.div`
  flex: 1 15%;
  display: none;
  ${media.desktop`
  display: block;
  `}
`
const HeaderContent = styled.div`
  /* display: flex;
  flex: 3 100%; */
  width: 100%;
`

const HeaderTop = styled.div`
  flex: 1 0 0;
  display: flex;
  margin-bottom: 25px;
`

const HeaderLeftTop = styled.div`
  margin-top: 5px;
  position: absolute;
  width: 120px;
  z-index: 2;
`

const HeaderRightTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 6px;
  font-size: 20px;
  a {
        color: white;
        padding: 0px 10px 0px 10px;
        text-decoration: none;
        }
  
    span {
  
      margin-left: 10px;
      padding-left: 10px;  
      border-left: 2px solid white;
      }
  }

  
`

const HeaderBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  > a {
    color: white;
    text-decoration: none;
    padding: 6px 15px;
    border-radius: 5px;
    text-transform: uppercase;
    word-break: keep-all;
    font-size: 16px;
  }

  > a.active {
    background-color: rgba(255, 255, 255, 0.25);
  }
`

const LogoImage = styled(ImgLogo)`
  border-radius: 6px;
  border: 5px solid white;
  height: 150px;
  width: 150px;
`

const Header = props => {
  const { venue, logo } = props

  return (
    <>
      <MobilePWAHeader></MobilePWAHeader>
      <MobileHeader venue={venue} logo={logo}></MobileHeader>
      <DesktopTabletHeader>
        <HeaderSpacerLeft />
        <HeaderContent>
          <HeaderTop>
            <HeaderLeftTop>
              <Link to="/" title="Home">
                <LogoImage></LogoImage>
              </Link>
            </HeaderLeftTop>
            <HeaderRightTop>
              <SubNavLinks venue={venue}></SubNavLinks>
            </HeaderRightTop>
          </HeaderTop>
          <HeaderBottom>
            {process.env.GATSBY_ID_clients !== "56" && (
              <Link activeClassName="active" to={`/${venue.sitePath}`}>
                Order
              </Link>
            )}
            <Link activeClassName="active" to="/menu/">
              Menu
            </Link>
            {process.env.GATSBY_ID_clients === "56" && (
              <Link activeClassName="active" to="/location/">
                Locations
              </Link>
            )}
            <Link activeClassName="active" to="/about-us/">
              About us
            </Link>
            {process.env.GATSBY_ID_clients === "56" && (
              <Link activeClassName="active" to="/franchise/">
                Franchise
              </Link>
            )}
            {/* {process.env.GATSBY_ID_clients === "56" && (
              <Link activeClassName="active" to="/news">
                News
              </Link>
            )} */}
            <Link activeClassName="active" to="/coupons/">
              Coupons
            </Link>
            <a
              key="merch"
              rel="noopener noreferrer"
              aria-label="Merchandise"
              target="_blank"
              href="//toromerch.printavo.com/merch/mama-s-pizza-merch"
            >
              Merch
            </a>
          </HeaderBottom>
        </HeaderContent>
        <HeaderSpacerRight />
      </DesktopTabletHeader>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  logo: PropTypes.object,
  venue: PropTypes.object,
}

Header.defaultProps = {
  siteTitle: ``,
  logo: null,
  venue: {},
}

export default Header
