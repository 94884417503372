import React from "react"
import {
  FaYelp,
  FaFacebookSquare,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa"
import { Link } from "gatsby"

export default function subNavLinks({ venue }) {
  const twitter =
    venue &&
    venue.externalServices &&
    venue.externalServices.find(es => es.name === "twitter")
  const facebook =
    venue &&
    venue.externalServices &&
    venue.externalServices.find(es => es.name === "facebook")
  const instagram =
    venue &&
    venue.externalServices &&
    venue.externalServices.find(es => es.name === "instagram")
  const yelp =
    venue &&
    venue.externalServices &&
    venue.externalServices.find(es => es.name === "yelp")

  return (
    <>
      {process.env.GATSBY_ID_clients === "56" && (
        <>
          <Link to="/contact/">Contact Us</Link>

          <a
            key="twitter"
            rel="noopener noreferrer"
            aria-label="Twitter"
            target="_blank"
            href={`//twitter.com/MamasPizzaTX`}
          >
            <FaTwitterSquare alt="Twitter" />
          </a>
          <a
            key="Yelp"
            rel="noopener noreferrer"
            aria-label="Yelp"
            target="_blank"
            href={`//yelp.com/biz/mamas-pizza-fort-worth-2`}
          >
            <FaYelp alt="Yelp" />
          </a>
          <a
            key="Facebook"
            rel="noopener noreferrer"
            aria-label="Facebook"
            target="_blank"
            href={`//facebook.com/MamasPizzaTX`}
          >
            <FaFacebookSquare alt="Facebook" />
          </a>
          <a
            key="Instagram"
            rel="noopener noreferrer"
            aria-label="Instagram"
            target="_blank"
            href={`//instagram.com/TheMamasPizza`}
          >
            <FaInstagram alt="Instagram" />
          </a>
        </>
      )}

      {process.env.GATSBY_ID_clients !== "56" && (
        <>
          {twitter && twitter.url.length > 0 && (
            <a
              key="twitter"
              rel="noopener noreferrer"
              aria-label="Twitter"
              target="_blank"
              href={twitter.url}
            >
              <FaTwitterSquare alt="Twitter" />
            </a>
          )}
          {yelp && yelp.url.length > 0 && (
            <a
              key="Yelp"
              rel="noopener noreferrer"
              aria-label="Yelp"
              target="_blank"
              href={yelp.url}
            >
              <FaYelp alt="Yelp" />
            </a>
          )}
          {facebook && facebook.url.length > 0 && (
            <a
              key="Facebook"
              rel="noopener noreferrer"
              aria-label="Facebook"
              target="_blank"
              href={facebook.url}
            >
              <FaFacebookSquare alt="Facebook" />
            </a>
          )}
          {instagram && instagram.url.length > 0 && (
            <a
              key="Instagram"
              rel="noopener noreferrer"
              aria-label="Instagram"
              target="_blank"
              href={instagram.url}
            >
              <FaInstagram alt="Instagram" />
            </a>
          )}
        </>
      )}
    </>
  )
}
