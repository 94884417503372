import React from "react"
import { media } from "./mediaCSS"
import styled from "styled-components"
import ImgLogo from "./imgLogo"
import { Link } from "gatsby"
import { FaBars, FaMapMarkerAlt, FaTimes } from "react-icons/fa"
import SubNavLinks from "./subNavLinks"

const MobileHeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  background: #000000;s

  ${media.mobilePWA`
    display: none;
  `}

  ${media.tablet_desktop`
    display: none;
  `}
`

const MobileMenuContainer = styled.menu`
  &.isOpen {
    max-height: 600px;
    padding: 80px 10px 80px 10px;
    transition: max-height 0.5s linear;
  }

  &.isClosed {
    max-height: 0px;
    padding: 0px 10px 0px 10px;
    transition: max-height 0.25s linear;
    transition: padding 0.25s linear;
  }

  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;

  max-height: 0px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > a {
    background: #222;
    color: white;
    font-weight: 900;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
    padding: 25px 20px;
    margin: 5px 10px 10px 10px;
    width: 33%;
    text-align: center;
    &.active {
      background: #ffffff;
      color: #111111;
    }
  }
`

const MobileTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 3.75rem;

  .mobileButton {
    padding: 8px;
  }

  .mobileButtonMap {
    padding: 8px;
    color: white;
    font-size: 33px;
    margin-top: 10px;
  }

  #menuMobileBars.show {
    display: block;
  }

  #menuMobileBars.hide {
    display: none;
  }

  #menuMobileCloseIcon.hide {
    display: none;
  }

  #menuMobileCloseIcon.show {
    display: block;
  }
`

const LogoImage = styled(ImgLogo)`
  border-radius: 6px;
  border: 5px solid white;

  height: 150px;
  width: 150px;
`

const MobileSocialContainer = styled.div`
  display: flex;
  margin-top: 75px;
  justify-content: space-evenly;
  width: 90%;
  font-size: 24px;

  a {
    color: white;
    text-decoration: none;
  }
`

const MobileHeader = props => {
  const venue = (props && props.venue) || {}

  const toggleMenu = () => {
    const mobileMenu = document.getElementById("mobileMenu")

    if (mobileMenu.classList.contains("isClosed")) {
      mobileMenu.classList.replace("isClosed", "isOpen")
      document
        .getElementById("menuMobileCloseIcon")
        .classList.replace("hide", "show")

      document
        .getElementById("menuMobileBars")
        .classList.replace("show", "hide")
    } else {
      mobileMenu.classList.replace("isOpen", "isClosed")
      document
        .getElementById("menuMobileCloseIcon")
        .classList.replace("show", "hide")

      document
        .getElementById("menuMobileBars")
        .classList.replace("hide", "show")
    }
  }

  return (
    <MobileHeaderContainer>
      <MobileTopBar>
        <FaBars
          id="menuMobileBars"
          className="mobileButton show"
          onClick={() => {
            toggleMenu()
          }}
        ></FaBars>
        <FaTimes
          onClick={() => {
            toggleMenu()
          }}
          id="menuMobileCloseIcon"
          className="mobileButton hide"
        ></FaTimes>

        <Link
          style={{ height: "65px", width: "110px", zIndex: "2" }}
          title="Home"
          to="/"
        >
          <LogoImage></LogoImage>
        </Link>
        <a
          key="locations"
          rel="noopener noreferrer"
          aria-label="Locations"
          href={`/location`}
          className="mobileButtonMap"
        >
          <FaMapMarkerAlt />
        </a>
      </MobileTopBar>
      <MobileMenuContainer id="mobileMenu" className="isClosed">
        {process.env.GATSBY_ID_clients !== "56" && (
          <Link activeClassName="active" to={`/${venue.sitePath}`}>
            Order
          </Link>
        )}
        <Link activeClassName="active" to="/menu">
          Menu
        </Link>
        {process.env.GATSBY_ID_clients === "56" && (
          <Link activeClassName="active" to="/location/">
            Locations
          </Link>
        )}
        <Link activeClassName="active" to="/about-us/">
          About us
        </Link>
        {process.env.GATSBY_ID_clients === "56" && (
          <Link activeClassName="active" to="/franchise/">
            Franchise
          </Link>
        )}
        {process.env.GATSBY_ID_clients === "56" && (
          <Link activeClassName="active" to="/news/">
            News
          </Link>
        )}
        <Link activeClassName="active" to="/coupons/">
          Coupons
        </Link>
        <a
          key="merch"
          rel="noopener noreferrer"
          aria-label="Merchandise"
          target="_blank"
          href="//toromerch.printavo.com/merch/mama-s-pizza-merch"
        >
          Merch
        </a>
        <MobileSocialContainer>
          <SubNavLinks venue={venue}></SubNavLinks>
        </MobileSocialContainer>
      </MobileMenuContainer>
    </MobileHeaderContainer>
  )
}

export default MobileHeader
