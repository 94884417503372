/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "normalize.css"
import GlobalStyles from "../styles/GlobalStyles"
import Header from "./header"
import { media, styled } from "./mediaCSS"

const Footer = styled.footer`
  background: black;
  min-height: 80px;
  color: white;
  border-top: 3px solid #e51937;

  > div {
    margin: 0 auto;
    max-width: 960px;
    padding: 40px 1.0875rem 1.45rem;
    text-align: center;
  }
`

const FullHolder = styled.div``

const MainHolder = styled.div`
  display: flex;

  ${media.tablet_desktop`
    .leftPane, .rightPane {
      background: #ededed;
      flex: 1;
    }

    main {
    flex: 7;
    padding: 40px 50px;
  }

  `}

  main {
    background: white;
    padding: 20px 10px;
    width: 100%;
  }
`

const Layout = ({ children, isFullWidth }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      allVenue {
        nodes {
          ID_clients
          address1
          address2
          venueName
          venueNameShort
          longitude
          latitude
          sitePath
          phone
          fullSiteUrl
          hours {
            CloseTime
            DayOfWeek
            OpenTime
          }
          externalServices {
            name
            url
          }
        }
      }
    }
  `)

  //const settings = data.footer.nodes[0]

  return (
    <>
      <GlobalStyles />
      <Header
        siteTitle="Title"
        venue={data.allVenue.nodes.find(
          v => v.ID_clients === Number(process.env.GATSBY_ID_clients)
        )}
      />
      {isFullWidth && <FullHolder>{children}</FullHolder>}
      {!isFullWidth && (
        <MainHolder>
          <div className="leftPane"></div>
          <main>{children}</main>
          <div className="rightPane"></div>
        </MainHolder>
      )}
      <Footer>
        <div>© {new Date().getFullYear()} - Mama's Pizza</div>
      </Footer>
    </>
  )
}

Layout.defaultProps = {
  children: [],
  isFullWidth: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isFullWidth: PropTypes.bool,
}

export default Layout
