import { css } from "styled-components"
import styled from "styled-components"
import { createGlobalStyle } from "styled-components"

const sizes = {
  desktop: [769, 3000],
  tablet: [577, 768],
  mobile: [0, 576],
  mobilePWA: [0, 576],
  tablet_desktop: [577, 3000],
}

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => {
    if (label.includes("PWA")) {
      return css`
        @media (max-width: ${sizes[
            label
          ][1]}px) and (display-mode: standalone) {
          ${css(...args)}
        }
      `
    } else {
      return css`
        @media (min-width: ${sizes[label][0]}px) and (max-width: ${sizes[
            label
          ][1]}px) {
          ${css(...args)}
        }
      `
    }
  }

  return acc
}, {})

const GlobalStyle = createGlobalStyle`
  html, body {
    background: #FFFFFF;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;

    box-sizing: border-box;
  }
  
*,
*:before,
*:after {
  box-sizing: inherit;
}



  @keyframes slideDown {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0%);
  }
}



`

export { media, GlobalStyle, styled }
